import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Image,
  Container,
  Tab,
  Nav,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { getSuccessNotificationMessage } from "../../helper/ToastNotification";
import CopyToClipboard from "react-copy-to-clipboard";

const ViewPrivateKey = (props) => {
  const navigate = useNavigate();
  const [key, setKey] = useState("");

  useEffect(() => {
    if (
      (Object.keys(props.verifyMobile.data).length > 0 &&
        props.verifyMobile.data.private_passphrase) ||
      (Object.keys(props.registerInputData.data).length > 0 &&
        props.registerInputData.data.data.private_passphrase)
    ) {
      setKey(
        Object.keys(props.verifyMobile.data).length > 0
          ? props.verifyMobile.data.private_passphrase
          : props.registerInputData.data.data.private_passphrase
      );
    } else {
      navigate("/");
    }
  }, []);

  const onCopy = () => {
    getSuccessNotificationMessage("Private key copied");
  };

  const next = () => {
    navigate("/onboarding");
  };

  return (
    <>
      <div className="authentication-sec">
        <Container>
          <div className="autentication-box">
            <Row className="justify-content-center">
              <Col md={6}>
                <div className="login-auth-sec">
                  <div className="sign-in-sec mt-4">
                    <div className="view-privte-key-sec">
                      <h3>Congratulations!</h3>

                      <h4>Welcome to the Marketplace!</h4>
                      <h5>
                        We need more information about you to customize your experience
                      </h5>
                      <p className="private-key-warning">
                        <span>Warning: </span> Please store your private key in a secure place, and never share it with anyone. Anyone with your private key can steal any assets held in your account.
                      </p>
                    </div>
                    <div className="private-key-content">
                      <h5>Private Key:</h5>
                      <div className="private-key-box">
                        <p>{key}</p>
                        <div className="private-key-btn">
                          <CopyToClipboard text={key} onCopy={() => onCopy()}>
                            <Button>
                              <Image
                                src={
                                  window.location.origin +
                                  "/images/auth/copy.svg"
                                }
                              />
                              <span>Copy</span>
                            </Button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>

                    <div className="login-btn mt-4">
                      <Button className="default-btn" onClick={() => next()}>
                        Get started
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  verifyMobile: state.walletApp.verifyMobile,
  registerInputData: state.users.registerInputData,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(ViewPrivateKey);
